@import-normalize;
@import '~antd/dist/antd.css';

@font-face {
  font-family: 'NotoSans';
  src: url(./styles/fonts/NotoSans-Thin.ttf) format('truetype');
  font-weight: 100;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: 'NotoSans';
  src: url(./styles/fonts/NotoSans-ExtraLight.ttf) format('truetype');
  font-weight: 200;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: 'NotoSans';
  src: url(./styles/fonts/NotoSans-Light.ttf) format('truetype');
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: 'NotoSans';
  src: url(./styles/fonts/NotoSans-Regular.ttf) format('truetype');
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: 'NotoSans';
  src: url(./styles/fonts/NotoSans-Medium.ttf) format('truetype');
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: 'NotoSans';
  src: url(./styles/fonts/NotoSans-MediumItalic.ttf) format('truetype');
  font-weight: 500;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: 'NotoSans';
  src: url(./styles/fonts/NotoSans-SemiBold.ttf) format('truetype');
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: 'NotoSans';
  src: url(./styles/fonts/NotoSans-Bold.ttf) format('truetype');
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: 'NotoSans';
  src: url(./styles/fonts/NotoSans-BoldItalic.ttf) format('truetype');
  font-weight: 700;
  font-style: italic;
  font-stretch: normal;
}
